import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import '../../styles/css/CreatorAwards.css';
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";
import posed, {PoseGroup} from "react-pose";
import sampleScoreboardTopImage from "../../styles/images/top_scoreboard.png";
import '../../styles/css/CustomScoreboard.css';
import {Textfit} from "react-textfit";
import Loading from "../utils/Loading";

const Trans = posed.div({
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class CustomScoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            startAnimating: true,
            loading: true
            // triviaGameOn: false,
        };
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        })
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        })

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true,
            then(data){
                this.setState({
                    loading:false
                })
            }
        });
    }

    setUpQuestionsWithAnswerCount(userAnswers, questions){
        let finalQuestionTally = {};
        let questionIdArray = [];
        for(let questionIndex in questions){
            let question = questions[questionIndex];
            let questionId = question.id;
            finalQuestionTally[questionId] = {};
            finalQuestionTally[questionId].totalPicks = 0;
            finalQuestionTally[questionId].answers = {};
            let questionAnswers = question.answers;
            for(let answerIndex in questionAnswers){
                let answer = questionAnswers[answerIndex];
                let answerId = answer.id;
                finalQuestionTally[questionId].answers[answerId] = 0;
            }
            questionIdArray.push(questions[questionIndex].id);
        }
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let answerObject = userAnswers[answerIndex];
                for(let questionIndex in questionIdArray){
                    let questionId = questionIdArray[questionIndex];
                    if(answerObject[questionId]){
                        finalQuestionTally[questionId].totalPicks++;
                        let answerId =  answerObject[questionId];
                        finalQuestionTally[questionId].answers[answerId]++;
                    };
                }
            }
        }
        return finalQuestionTally;
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        isVisible: true
      }), 1);
    }

    handleImageErrored() {
      console.log("Image failed to load")
      this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentGame.questionToShow === this.state.currentGame.questionToShow - 1){
            this.setState({startAnimating:false}, ()=>{
                this.setState({startAnimating:true})
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const questions = currentGame.questions || [];
        const zoomScoreboard = tenantVariables.zoomScoreboard || "100%"
        let questionCount = {};
        let questionTopText = tenantVariables.topScoreboardQuestionSize || "44";
        questionTopText = questionTopText + "px";
        let currentQuestion = {};
        let itemsToIterate = [];
        if(questions.length > 0){
          currentQuestion = questions[currentGame.questionToShow] || questions[0];
          questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
            itemsToIterate = currentQuestion.answers;
        }
        let maxWidthOfEverything = 0;
        let addUpBodyHeight = 0;
        let body = document.body, html = document.documentElement;
        let gridItemHeight = 0;
        let answerSectionRemainingHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        const topImageScoreboardElement = document.getElementById('topImageScoreboard');
        const questionSectionElement = document.getElementById('questionSection');
        const actualQuestionSectionElement = document.getElementById('actualParagraphHeight');
        if(topImageScoreboardElement && questionSectionElement){
            console.log(questionSectionElement.getBoundingClientRect().height)
            console.log(actualQuestionSectionElement.scrollHeight)
            maxWidthOfEverything = topImageScoreboardElement.width;
            let topScoreboardHeight = Math.max( topImageScoreboardElement.height, topImageScoreboardElement.offsetHeight, topImageScoreboardElement.scrollHeight);
            console.log(questionSectionElement.scrollHeight, questionSectionElement.offsetHeight)
            addUpBodyHeight = topScoreboardHeight+Math.max( questionSectionElement.scrollHeight, questionSectionElement.offsetHeight);
            console.log(addUpBodyHeight, answerSectionRemainingHeight)
            answerSectionRemainingHeight -= addUpBodyHeight;
            console.log(answerSectionRemainingHeight)
            gridItemHeight = answerSectionRemainingHeight/itemsToIterate.length - 20;
            if(gridItemHeight > addUpBodyHeight){
                gridItemHeight = topScoreboardHeight;
            } else if(gridItemHeight < 0){
                console.log("trouble getting element")
                gridItemHeight = 100
            }
        }
        const backgroundImage = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const topOfScoreboardImage = tenantVariables.topOfScoreboardImage || sampleScoreboardTopImage;
        return (
            <div className="flex-container-scoreboard" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="grid-wrapper-scoreboard" style={{zoom:zoomScoreboard}}>
                    <div className="">
                        <img src={topOfScoreboardImage} alt="" id="topImageScoreboard" onLoad={this.handleImageLoaded.bind(this)}/>
                    </div>
                    <div style={{maxWidth: maxWidthOfEverything, margin:"auto"}} id="questionSection">
                        <p id="actualParagraphHeight" style={{textShadow: "2px 2px #000", wordWrap: "break-word", fontWeight:700, color:tenantVariables.progressBarQuestionTextColor, fontSize:questionTopText}}>{currentQuestion.questionText}</p>
                    </div>
                    {/*{this.state.startAnimating &&*/}
                    {/*    <PoseGroup animateOnMount={true} delta={1}>*/}
                    {/*        <Trans key={233}>*/}
                    {/*            <div className="grid-container-scoreboard" style={{maxWidth: maxWidthOfEverything, gridAutoFlow:"row", transform:"translateY(0%)", padding:0, gridTemplateRows: gridItemHeight, gridAutoRows:gridItemHeight}}>*/}
                    {/*                {*/}
                    {/*                    itemsToIterate.sort((a,b)=>a.order-b.order).map(function(item,i){*/}
                    {/*                        let container;*/}
                    {/*                        if(document.getElementsByClassName('grid-item')){*/}
                    {/*                            container = document.getElementsByClassName('grid-item')[0];*/}
                    {/*                        }*/}
                    {/*                        let progressWidth = "0";*/}
                    {/*                        let totalCount = 0;*/}
                    {/*                        let totalAnswers = 0;*/}
                    {/*                        let percent = "0%";*/}
                    {/*                        if(currentQuestion.answers){*/}
                    {/*                            totalCount = questionCount[currentQuestion.id].totalPicks;*/}
                    {/*                            totalAnswers = questionCount[currentQuestion.id].answers[item.id];*/}
                    {/*                        } else {*/}
                    {/*                            for(let questionIndex in questionCount){*/}
                    {/*                                for(let answerIndex in questionCount[questionIndex].answers){*/}
                    {/*                                    if(item.id === answerIndex){*/}
                    {/*                                        totalCount = questionCount[questionIndex].totalPicks*/}
                    {/*                                        totalAnswers = questionCount[questionIndex].answers[answerIndex]*/}
                    {/*                                    }*/}
                    {/*                                }*/}
                    {/*                            }*/}
                    {/*                        }*/}
                    {/*                        if(container && totalCount>0){*/}
                    {/*                            progressWidth = parseInt(container.clientWidth * totalAnswers/totalCount, 10)*/}
                    {/*                        }*/}
                    {/*                        let progressBarColor = tenantVariables.progressBarColor || "transparent";*/}
                    {/*                        let answerTextColor = tenantVariables.progressBarTextColor || "transparent";*/}
                    {/*                        let scoreboardBackgroundColor = tenantVariables.progressBarBackgroundColor || "transparent";*/}
                    {/*                        if(currentGame.screenToShow === 2 && item.correct){*/}
                    {/*                            scoreboardBackgroundColor = tenantVariables.progressBarEndWinColor || "transparent";*/}
                    {/*                            progressBarColor = tenantVariables.progressBarEndWinColor || "transparent";*/}
                    {/*                        }*/}
                    {/*                        if(totalCount > 0){*/}
                    {/*                            percent = (parseInt(totalAnswers/totalCount*100,10)).toString()+"%";*/}
                    {/*                        }*/}
                    {/*                        return <div*/}
                    {/*                            key={i}*/}
                    {/*                            id={item.id}*/}
                    {/*                            // className="grid-item answerItemClass"*/}
                    {/*                            style={{display: "flex", flexDirection: "row", background: scoreboardBackgroundColor, color:answerTextColor, boxShadow:"unset", outline:"1px solid "+answerTextColor, minWidth:100, lineHeight:1,border:"none",padding:0,borderRadius:0,zIndex:-2}}>*/}
                    {/*                            <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible"}}>*/}
                    {/*                                <div className="progress-container" style={{width:progressWidth, backgroundColor:progressBarColor, zIndex:-1, borderRadius:0}}/>*/}
                    {/*                            </div>*/}
                    {/*                            <div style={{display:"flex", flexDirection: "row", alignItems: "center", width:"100%"}}>*/}
                    {/*                                <div style={{display: item.answerImage?"flex":"none", height:"100%", alignItems:"center"}}>*/}
                    {/*                                    <img src={item.answerImage} width={"auto"} height={"auto"} alt="" style={{marginLeft:5, maxHeight:"100%", display:'flex', alignItems:"center"}}/>*/}
                    {/*                                </div>*/}
                    {/*                                <Textfit mode="multi" min={34} max={1000} style={{flexGrow:3, height: gridItemHeight, alignItems:"center",display:"flex",marginLeft:5}}>*/}
                    {/*                                    {item.answerText}*/}
                    {/*                                </Textfit>*/}
                    {/*                                <Textfit forceSingleModeWidth={false} mode="single" min={34} max={1000} style={{height: gridItemHeight,alignItems:"center",display:"flex", flexGrow:1, justifyContent: 'flex-end'}}>*/}
                    {/*                                    {percent}*/}
                    {/*                                </Textfit>*/}
                    {/*                                /!*<div style={{marginLeft:"auto"}}>*!/*/}
                    {/*                                /!*    <span style={{fontSize:"1.5em", width:"100%", padding:5}}>{percent}</span>*!/*/}
                    {/*                                /!*</div>*!/*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    }, this)*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </Trans>*/}
                    {/*    </PoseGroup>*/}
                    {/*}*/}
                </div>
            </div>
          )
        }
}

export default CustomScoreboard;
