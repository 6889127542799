import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import '../../styles/css/react-confirm-alert.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Home.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          userEmail: localStorage.getItem('userEmail') || false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

    componentDidMount() {
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(!navigator.cookieEnabled){
        alert("You do not have cookies enabled! Please change the settings of your browser");
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    validateEmail(email) {
    let re;
    re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

    skipLogin(){
      const tenantVariables = this.state.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.ERRORTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      const randomElement = Math.floor((Math.random() * 100000) + 1);
      const sessionTokenString = Date.now().toString() + randomElement.toString();
      sessionStorage.setItem("tempToken", sessionTokenString);
      this.setState({
        redirect: true,
      })
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();
      const inputtedEmail = this.state.inputtedEmail.trim();
      const tenantVariables = this.props.variables;
      const inputtedName = this.state.inputtedName.trim();
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;

      if(!tenantVariables.doNotCollectEmail && inputtedEmail === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter An Email!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(!tenantVariables.doNotCollectEmail && !this.validateEmail(inputtedEmail)){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Unable to read email, please check to make sure it is entered correctly",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Name!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Zip Code!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please agree to rules and regulations!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      // Play with ' + this.state.inputedEmail + '?'
      if(tenantVariables.doNotCollectEmail){
        this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef)
        return
      }
      var buttons = [
        {
          label: 'NO',
          onClick: () => {
            console.log("Canceled")
            document.body.classList.remove('react-confirm-alert-body-element')
            const target = document.getElementById('react-confirm-alert')
            target.parentNode.removeChild(target)
            const svg = document.getElementById('react-confirm-alert-firm-svg')
            svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur')
          }
        },
        {
          label: "YES",
          onClick: () => {
            this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef)
          }
        }
      ]
      var message = "We'll send your prize there if you win and we don't want to send it to the wrong place\n"+ inputtedEmail + "\nIs that correct?"
      var primaryColor = this.props.variables.primaryColor || "black"
      var secondaryColor = this.props.variables.secondaryColor || "white"
      confirmAlert({
        title: 'Confirm Email',
        message: message,
        customUI: ({ title, message, onClose }) =>
            <div className='react-confirm-alert-body'>
              {title && <h1 style={{fontWeight:'900'}}>{title}</h1>}
              {/*"We'll send your prize there if you win and we don't want to send it to the wrong place"*/}
              <span className="email-in-popup" style={{fontWeight:'900', color:'black', fontFamily:'Oswald'}}>{inputtedEmail}</span>
              <br/>
              <div className='react-confirm-alert-button-group'>
                {buttons.map((button, i) => (
                    <button
                        key={i}
                        style={{backgroundColor:primaryColor, color: secondaryColor}}
                        onClick={() => {
                          button.onClick()
                        }
                        }
                    >
                      {button.label}
                    </button>
                ))}
              </div>
            </div>
      })
    }

    createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      if(inputtedEmail){
        base64EncodedEmail = btoa(inputtedEmail);
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['uid'] = base64EncodedEmail;
      userObject['zipCode'] = inputtedZipCode;
      userObject['name'] = inputtedName;
      userObject['optIn'] = agree_to_promotionRef;
      const vm = this;
      base.post('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            document.body.classList.remove('react-confirm-alert-body-element')
            const target = document.getElementById('react-confirm-alert')
            if(target) {
              target.parentNode.removeChild(target)
            }
            const svg = document.getElementById('react-confirm-alert-firm-svg')
            if(svg){
              svg.parentNode.removeChild(svg)
            }
            document.body.children[0].classList.remove('react-confirm-alert-blur')
            try {
              if(inputtedEmail){
                localStorage.setItem('userEmail', inputtedEmail);
              } else {
                localStorage.setItem('userEmail', base64EncodedEmail);
              }
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert("Unable to log you in! Check your settings and try again!")
            }

          } else {
            alert("Oh No! There was an error please try again!")
          }
        }
      })
    }

    render() {
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      const htmlParameterEmail = this.getURLParameter("email");
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const rulesRegsText = tenantRules.rulesAndRegsText || "Please agree to the Rules and Regulations";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      if (redirect || this.state.userEmail) {
          return (
              <Redirect to="/" />
          )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail ? "none":""}}>Enter your email to play</h4>
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: htmlParameterEmail ? "none":""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Email" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Name" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Zip Code" />
                      </div>
                      <div style={{display:tenantVariables.mandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{window.open(link, '_blank');}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!!tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeToPromotionId">{promotionText}</label>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>LET'S PLAY!</strong></button>
                    </form>
                    <div style={{display: tenantVariables.allowAnonymousLogin ? "" : "none"}}>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="skipButton" onClick={()=>this.skipLogin()}><strong>SKIP</strong></button>
                    </div>
                    <button style={{display: howToPlayLink ? "" : "none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{window.open(howToPlayLink, '_blank');}}><strong>How To Play</strong></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }
}

export default Login;
